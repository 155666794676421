<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 d-flex">
        <div class="wrapper w-100 m-auto">
          <div class="px-4 mb-5">
            <p class="titulo-pag">Plano de ação</p>
            <h1 class="mt-3">Criar plano de ação</h1>
          </div>
          <FormPlanoDeAcao />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormPlanoDeAcao from './FormPlanoDeAcao.vue';

export default {
  name: 'PlanosDeAcaoCadastro',
  components: {
    FormPlanoDeAcao
  }
};

</script>
<style scoped>
  .titulo-pag{
    font-size: 14px;
    font-weight:600;
    color: #0A85CA;
  }
  h1{
    font-size: 22px;
    font-weight: bolder;
  }
  h1+span{
    font-size: 14px;
    color: #717171;
  }
</style>
