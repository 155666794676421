<template>
  <form id="formCadastroPlanosDeAcao" action="" method="post" class="form-custom bg-white p-5" :class="{ 'was-validated': wasValidated}" @submit="checkForm">
    <input v-if="this.isUpdating" type="hidden" name="id" :value="registry.id" >
    <fieldset class="row w-100" :disabled="sendingState || readOnlyMode">
      <div class="col-12 mb-3">
        <label for="inputdesafio" class="form-label fw-bold">Desafio a ser superado</label>
        <textarea class="form-control" id="inputdesafio" name="desafio" rows="6" placeholder="Digite aqui algo que você deseja acrescentar" required></textarea>
        <div class="invalid-feedback">
          O desafio a ser superado é obrigatório
        </div>
      </div>
      <div class="col-12 mb-3">
        <label for="inputproposta" class="form-label fw-bold">Solução proposta</label>
        <textarea class="form-control" id="inputproposta" name="proposta" rows="6" placeholder="Digite aqui algo que você deseja acrescentar" required></textarea>
        <div class="invalid-feedback">
          A solução proposta é obrigatória
        </div>
      </div>
      <div class="col-12 mb-3">
        <label for="inputlista_de_envolvidos" class="form-label fw-bold">Atores envolvidos no plano de ação</label>
        <!-- <textarea class="form-control" id="inputlista_de_envolvidos" name="lista_de_envolvidos" rows="6" placeholder="Adicione os atores a serem relacionados a este plano" required></textarea> -->
        <ActorSearch :badgeClickCallback="addSelected" :registry="registry" />
        <input type="hidden" name="lista_de_envolvidos" id="inputlista_de_envolvidos" :value="actorSelected">
      </div>
      <div class="col-6 mb-3">
        <label for="inputdata_inicio" class="form-label fw-bold">Data de início previsto para o plano de ação</label>
        <input type="text" class="form-control" id="inputdata_inicio" name="data_inicio" placeholder="00/00/2022" onkeyup="
        var v = this.value;
        if (v.match(/^\d{2}$/) !== null) {
            this.value = v + '/';
        } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
            this.value = v + '/';
        }"
    maxlength="10">
      </div>
      <div class="col-6 mb-3">
        <label for="inputdata_conclusao" class="form-label fw-bold">Data de término previsto para o plano de ação</label>
        <input type="text" class="form-control" id="inputdata_conclusao" name="data_conclusao" placeholder="00/00/2022" onkeyup="
        var v = this.value;
        if (v.match(/^\d{2}$/) !== null) {
            this.value = v + '/';
        } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
            this.value = v + '/';
        }"
    maxlength="10">
      </div>

      <div class="col-12 mb-3">
        <label for="inputinformacoes_adicionais" class="form-label fw-bold">Informações adicionais ou observações</label>
        <textarea class="form-control" id="inputinformacoes_adicionais" name="informacoes_adicionais" rows="6" placeholder="Digite aqui algo que você deseja acrescentar"></textarea>
      </div>
    </fieldset>
    <div class="row w-100 justify-content-center mt-5">
      <button v-if="!readOnlyMode" type="submit" class="col-4 mx-1 btn btn-primary rounded-pill" :disabled="sendingState" @click="wasValidated = true">
        <span v-if="sendingState">Registrando ... <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span>
        <span v-else>Criar plano de ação</span>
      </button>
    </div>
  </form>
  <div class="modal-mask d-flex justify-content-center align-items-center" v-if="showModal" @close="showModal = false">
      <modal v-if="vinculoAtor === true">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 d-flex justify-content-end" style="padding-top: 10px; padding-right: 20px;">
            <button class="btn" @click="showModal = false">
              <img src="../../assets/icon-close.png" alt="">
            </button>
          </div>
        </div>
        <h3 style="padding: 0 3em;">Cadastro realizado com sucesso</h3>
        <div class="container_box">
          <p class="p-4">Agradecemos as suas informações</p>
          <div class="d-flex justify-content-between pb-5 pt-3">
            <router-link class="nav-link mb-1 text-secondary bg-gray-100" :class="{ 'active': /^PlanosDeAcao.*$/.test($route.name) }" :to="{ name: 'PlanosDeAcao' }">
              <button class="btn btn-outline-primary px-3 rounded-pill" @click="showModal = false">
                  Ir para lista de Plano de Ação
              </button>
            </router-link>
            <router-link class="nav-link mb-1 text-secondary bg-gray-100" :class="{ 'active': /^PlanosDeAcao.*$/.test($route.name) }" :to="{ name: 'PlanosDeAcao.Cadastro' }">
              <button class="btn btn-primary px-4 py-2 rounded-pill" @click="showModal = false, reloadPage()">
                  Fazer novo cadastro
              </button>
            </router-link>
          </div>
        </div>
      </modal>

      <modal v-if="vinculoAtor === false">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 d-flex justify-content-end" style="padding-top: 10px; padding-right: 20px;">
            <button class="btn" @click="showModal = false">
              <img src="../../assets/icon-close.png" alt="">
            </button>
          </div>
        </div>
        <div class="container_box">
          <h3 style="padding: 0 3em;">Alerta!</h3>
          <p class="p-4">É necessário vincular pelo menos 1 ator ao plano de ação</p>
        </div>
      </modal>
  </div>
</template>

<script>

import { PlanosDeAcaoRepository } from '../../libs/repositories';
import ActorSearch from '../../components/ActorSearch.vue';
export default {
  name: 'FormPlanoDeAcao',
  props: {
    registry: {
      type: Object,
      required: false,
      default: null
    },
    readOnlyMode: {
      type: Boolean,
      requried: false,
      default: false
    }
  },
  components: {
    ActorSearch
  },
  data () {
    return {
      isUpdating: !!this.registry,
      sendingState: false,
      wasValidated: false,
      actorSelected: [],
      showModal: false,
      envolvidos: [],
      vinculoAtor: true
    };
  },
  mounted () {
    if (!this.isUpdating) return;

    /** @type {HTMLFormElement} */ // @ts-expect-error
    const form = document.getElementById('formCadastroPlanosDeAcao');

    this.registry.lista_de_envolvidos.forEach(element => {
      this.envolvidos.push(element.ator_id);
    });

    form.desafio.value = this.registry.desafio;
    form.proposta.value = this.registry.proposta;
    form.data_inicio.value = this.registry.data_inicio;
    form.data_conclusao.value = this.registry.data_conclusao;
    form.informacoes_adicionais.value = this.registry.informacoes_adicionais;
    form.lista_de_envolvidos.value = this.actorSelected.concat(this.envolvidos);
  },
  methods: {
    /**
     * @param {Event} event
     */
    async checkForm (event) {
      event.preventDefault();

      /** @type {HTMLFormElement} */ // @ts-expect-error
      const form = event.target;
      const formData = new FormData(form);
      if (form.lista_de_envolvidos.value === '') {
        this.wasValidated = false;
        this.showModal = true;
        this.vinculoAtor = false;
        return;
      } else {
        this.vinculoAtor = true;
      }

      if (!form.checkValidity()) {
        this.wasValidated = true;
        return;
      }

      this.sendingState = true;

      const model = {};

      formData.forEach((value, key) => {
        // @TODO para os primeiros teste fica assim
        if (key === 'lista_de_envolvidos') {
          model[key] = value.toString().split(',');
        } else {
          model[key] = value;
        }
      });
      formData.forEach((value, key) => {
        // @TODO para os primeiros teste fica assim
        if (key === 'lista_de_envolvidos') {
          model[key] = value.toString().split(',').concat(this.envolvidos).filter(function (i) {
            return i;
          }); ;
        } else {
          model[key] = value;
        }
        if (!this.isUpdating) {
          if (key === 'data_inicio') {
            model[key] = this.formatarData(value.toString());
          }
          if (key === 'data_conclusao') {
            model[key] = this.formatarData(value.toString());
          }
        }
      });

      const saved = (this.isUpdating)
        ? await PlanosDeAcaoRepository.update(model.id, model)
        : await PlanosDeAcaoRepository.add(model);

      this.sendingState = false;
      this.wasValidated = false;

      if (!this.isUpdating && saved) {
        form.reset();
      }
      this.showModal = true;
      // eslint-disable-next-line
      console.log(saved ? 'sucesso' : 'duplicado');
    },
    formatarData (data) {
      // da "split" no 'espaço'
      var d = data.split(' ')[0]; // recupera 23/03/2012
      var dsplit = d.split('/'); // recupera o array(23, 03, 2012);
      // cria o objeto date
      var novaData = new Date(dsplit[2], dsplit[1] - 1, dsplit[0]);
      // retorna o objeto date
      return novaData;
    },
    addSelected (actor) {
      this.actorSelected.push(actor.id);
    },
    reloadPage () {
      window.location.reload();
    }
  }
};
</script>
<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
  }
  modal {
    background-color: white;
    display: block;
    max-width: 540px;
    width: 100%;
    border-radius: 5px;
    text-align: center;
  }
  .form-control:disabled, .form-control[readonly] {
    background-color: #ffffff;
    opacity: 1;
  }
</style>
